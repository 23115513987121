/* Adding variables */
:root{
    --max-width: 400px;
    --primary-color: #015fc3;
    --border-color: #cccccc;
    --warning-color:#c82333;
    --succes-color: #28a745;
}

/* Setting font-family */
body{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

h3, p{
    padding: 0;
    margin: 0;
}

/* Containers */
.container--l{
    max-width: var(--max-width);
    margin-inline: auto;
    padding: 2dvw;
}

/* Button styles */
button {
    display: inline-block;
    padding: 0.7rem 1.4rem;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    background-color:rgb(215, 215, 215) ;

}

.primary{
    background-color: var(--primary-color);
    color: #fff;
}

.delete{
    background-color: var(--warning-color);
    display: flex;
    color:white;
    padding: .6rem 1.2rem;

}

.delete--task{
    background-color: var(--warning-color);
    display: flex;
    color:white;
    padding: .6rem 1.2rem;
    justify-self: right;
}

.add{
    background-color: var(--succes-color);
    color: white;
}


/* Form styles */
form {
    max-width: 400px;
    margin-block: 1rem;
}

.form__group {
    margin-block-end: 1.5rem;
  }

label {
    display: block;
    font-weight: bold;
    margin-block-end: 0.2rem;
}

input[type="text"],
select {
    width: 100%;
    max-width: 90dvw;
    padding: 0.75rem;
    border: 1px solid var(--border-color);
    border-radius: 0.3rem;
}

/* Todo-styles */

.todo{
    margin-inline: auto;
}
  
input[type="checkbox"]{
    height: 1.7rem;
    width: 1.7rem;
    justify-self: center;
}


.todo__item{
    display: grid;
    align-items: center;
    justify-content: space-between;
    grid-template-columns: 1fr 4fr 1fr;
    padding-right: 0.5rem;
    max-width: 400px;
    background-color: #f5f5f5;
    box-shadow: 0 6px 18px rgba(0, 0, 0, .1);
    margin-block: 0.5em;
    border: 1px solid rgb(225, 225, 225);
    border-radius: 0.3rem;
}

.todo__item--details{
    justify-self: left;
    border-left: 1px solid rgb(125, 125, 125);
    padding: 1rem;
    margin-block: 1em;
}

i{
    border-style: none;
    font-size: 1.2rem;
    color: #ffffff;
    font-weight: 400;
  }

.completed--true{
    opacity: .5;
    background: rgb(199, 199, 199);
}
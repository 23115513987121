:root {
  --max-width: 400px;
  --primary-color: #015fc3;
  --border-color: #ccc;
  --warning-color: #c82333;
  --succes-color: #28a745;
}

body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
}

h3, p {
  margin: 0;
  padding: 0;
}

.container--l {
  max-width: var(--max-width);
  margin-inline: auto;
  padding: 2dvw;
}

button {
  cursor: pointer;
  background-color: #d7d7d7;
  border: none;
  border-radius: 5px;
  padding: .7rem 1.4rem;
  font-weight: bold;
  display: inline-block;
}

.primary {
  background-color: var(--primary-color);
  color: #fff;
}

.delete {
  background-color: var(--warning-color);
  color: #fff;
  padding: .6rem 1.2rem;
  display: flex;
}

.delete--task {
  background-color: var(--warning-color);
  color: #fff;
  justify-self: right;
  padding: .6rem 1.2rem;
  display: flex;
}

.add {
  background-color: var(--succes-color);
  color: #fff;
}

form {
  max-width: 400px;
  margin-block: 1rem;
}

.form__group {
  margin-block-end: 1.5rem;
}

label {
  margin-block-end: .2rem;
  font-weight: bold;
  display: block;
}

input[type="text"], select {
  border: 1px solid var(--border-color);
  border-radius: .3rem;
  width: 100%;
  max-width: 90dvw;
  padding: .75rem;
}

.todo {
  margin-inline: auto;
}

input[type="checkbox"] {
  justify-self: center;
  width: 1.7rem;
  height: 1.7rem;
}

.todo__item {
  background-color: #f5f5f5;
  border: 1px solid #e1e1e1;
  border-radius: .3rem;
  grid-template-columns: 1fr 4fr 1fr;
  justify-content: space-between;
  align-items: center;
  max-width: 400px;
  margin-block: .5em;
  padding-right: .5rem;
  display: grid;
  box-shadow: 0 6px 18px #0000001a;
}

.todo__item--details {
  border-left: 1px solid #7d7d7d;
  justify-self: left;
  margin-block: 1em;
  padding: 1rem;
}

i {
  color: #fff;
  border-style: none;
  font-size: 1.2rem;
  font-weight: 400;
}

.completed--true {
  opacity: .5;
  background: #c7c7c7;
}
/*# sourceMappingURL=index.b72dd426.css.map */
